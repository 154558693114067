var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:_vm.$style.container},[_c('Search',{class:_vm.$style.search,attrs:{"items":_vm.searchProducts,"is-show":_vm.isShow},on:{"clear":_vm.handleClearSearch,"submit":_vm.submitSearch,"click":_vm.submitSearch},model:{value:(_vm.searchItem),callback:function ($$v) {_vm.searchItem=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"searchItem"}},_vm._l((_vm.searchProducts),function(item,index){return _c('div',{key:index,class:_vm.$style.item},[_c('router-link',{class:_vm.$style.link,attrs:{"to":_vm.getRoute({
            route: _vm.$options.ADDSELLERS_ADMIN_ROUTES.ORDERS.OFFSELLERS.VIEW,
            params: { id: item.id },
          })}},[_c('span',[_vm._v("Имя Диллера"),_c('br'),_vm._v(_vm._s(item.dealerAccount.name))]),_c('span',[_vm._v("Номер"),_c('br'),_vm._v(_vm._s(item.name))]),_c('span',[_vm._v("Оформлен"),_c('br'),_vm._v(_vm._s(_vm.fomateDateRow(item.createdAt)))]),_c('span',[_vm._v("Цена"),_c('br'),_vm._v(_vm._s(item.price))])])],1)}),0),_c('vue-good-table',{class:_vm.$style.table,attrs:{"columns":_vm.columns,"rows":_vm.list,"styleClass":"vgt-table striped"},on:{"on-sort-change":_vm.onSortChange},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'dealerName')?_c('router-link',{attrs:{"to":_vm.getRoute({
            route: _vm.$options.ADDSELLERS_ADMIN_ROUTES.DEALERS.EDIT,
            params: { id: props.row.dealerAccount.id },
          })}},[_c('span',[_vm._v(" "+_vm._s(props.row.dealerAccount.name)+" ")])]):_vm._e(),(props.column.field === 'name')?_c('span',[_vm._v(" "+_vm._s(props.row.dealerAccount.offsellerName)+" ")]):_vm._e(),(props.column.field === 'name')?_c('span',[_vm._v(" "+_vm._s(props.row.name)+" ")]):_vm._e(),(props.column.field === 'price')?_c('span',[_vm._v(" "+_vm._s(props.row.productsPrice)+" ")]):_vm._e(),(props.column.field === 'createdAt')?_c('span',[_vm._v(" "+_vm._s(_vm.fomateDateRow(props.row.createdAt))+" ")]):_vm._e(),(props.column.field === 'discountSize')?_c('span',[_vm._v(" "+_vm._s(props.row.discountSize)+"% ")]):(props.column.field === 'action')?_c('span',{class:_vm.$style.actions},[_c('ActionButtons',{class:_vm.$style.actionsBtn,attrs:{"add-trash":false,"view-link":_vm.getRoute({
              route: _vm.$options.ADDSELLERS_ADMIN_ROUTES.ORDERS.OFFSELLERS.VIEW,
              params: { id: props.row.id },
            })}})],1):_vm._e()]}}])}),(_vm.pageCount > 1)?_c('Pagination',{attrs:{"curPage":_vm.query.page,"lastPage":_vm.pageCount},on:{"go-next":function($event){return _vm.goNext()},"go-prev":function($event){return _vm.goPrev()}}}):_vm._e(),_c('Loader',{attrs:{"is-loading":_vm.isLoading}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }