<template>
  <div :class="$style.container">
    <Search
      :class="$style.search"
      v-model.trim="searchItem"
      :items="searchProducts"
      :is-show="isShow"
      @clear="handleClearSearch"
      @submit="submitSearch"
      @click="submitSearch"
    >
      <div
        v-for="(item, index) in searchProducts"
        :key="index"
        :class="$style.item"
      >
        <router-link
          :to="
            getRoute({
              route: $options.ADDSELLERS_ADMIN_ROUTES.ORDERS.OFFSELLERS.VIEW,
              params: { id: item.id },
            })
          "
          :class="$style.link"
        >
          <span>Имя Диллера<br />{{ item.dealerAccount.name }}</span>
          <span>Номер<br />{{ item.name }}</span>
          <span>Оформлен<br />{{ fomateDateRow(item.createdAt) }}</span>
          <span>Цена<br />{{ item.price }}</span>
        </router-link>
      </div>
    </Search>
    <vue-good-table
      :class="$style.table"
      :columns="columns"
      :rows="list"
      styleClass="vgt-table striped"
      @on-sort-change="onSortChange"
    >
      <template slot="table-row" slot-scope="props">
        <router-link
          v-if="props.column.field === 'dealerName'"
          :to="
            getRoute({
              route: $options.ADDSELLERS_ADMIN_ROUTES.DEALERS.EDIT,
              params: { id: props.row.dealerAccount.id },
            })
          "
        >
          <span>
            {{ props.row.dealerAccount.name }}
          </span>
        </router-link>
        <span v-if="props.column.field === 'name'">
          {{ props.row.dealerAccount.offsellerName }}
        </span>
        <span v-if="props.column.field === 'name'"> {{ props.row.name }} </span
        ><span v-if="props.column.field === 'price'">
          {{ props.row.productsPrice }}
        </span>
        <span v-if="props.column.field === 'createdAt'">
          {{ fomateDateRow(props.row.createdAt) }}
        </span>
        <span v-if="props.column.field === 'discountSize'">
          {{ props.row.discountSize }}%
        </span>
        <span
          v-else-if="props.column.field === 'action'"
          :class="$style.actions"
        >
          <ActionButtons
            :add-trash="false"
            :view-link="
              getRoute({
                route: $options.ADDSELLERS_ADMIN_ROUTES.ORDERS.OFFSELLERS.VIEW,
                params: { id: props.row.id },
              })
            "
            :class="$style.actionsBtn"
          />
        </span>
      </template>
    </vue-good-table>
    <Pagination
      v-if="pageCount > 1"
      @go-next="goNext()"
      @go-prev="goPrev()"
      :curPage="query.page"
      :lastPage="pageCount"
    />
    <Loader :is-loading="isLoading" />
  </div>
</template>

<script>
import delivery from '@/delivery'
import { formatDate, formatWarehouse } from '@/helpers/index.js'
import { debounce } from '@/helpers/debounce.js'
import { ADDSELLERS_ADMIN_ROUTES, getRoute } from '@/constants/routing'

import Pagination from '@/components/atoms/Paginations.vue'
import Search from '@/components/atoms/InputSearchable.vue'
import Loader from '@/components/atoms/LoadingBar.vue'
import ActionButtons from '@/components/atoms/ActionsButtons.vue'

export default {
  ADDSELLERS_ADMIN_ROUTES,
  components: { Pagination, Search, Loader, ActionButtons },
  created() {
    this.handleSearch = debounce(this.getSearchList, 500)
  },
  mounted() {
    this.getOrders()
  },
  watch: {
    async searchItem() {
      this.isShow = true
      await this.handleSearch()
    },
  },
  data() {
    return {
      columns: [
        {
          label: 'Имя диллера',
          field: 'dealerName',
        },
        {
          label: 'Номер',
          field: 'name',
          tooltip: 'Номер заказа',
        },
        {
          label: 'Оформлен',
          field: 'createdAt',
        },
        {
          label: 'Сумма',
          field: 'price',
        },
        {
          label: 'Скидка',
          field: 'discountSize',
          sortable: false,
        },
        {
          label: '',
          field: 'action',
          sortable: false,
        },
      ],
      list: [],
      count: 0,
      query: {
        page: 1,
        limit: 20,
        search: null,
        orderBy: 'created_at',
        orderDir: 'desc',
      },
      limitSearch: 5,
      searchItem: '',
      searchProducts: [],
      isShow: false,
      isSearch: false,
      isLoading: false,
    }
  },
  computed: {
    pageCount() {
      return Math.ceil(this.count / this.query.limit)
    },
  },
  methods: {
    async getOrders() {
      this.isLoading = true
      const { value, error } =
        await delivery.AddwineCore.OrdersActions.getOffSellersList(this.query)

      this.isLoading = false

      if (error) return

      this.list = value.data
      this.count = value.meta.count
    },
    async goNext() {
      if (this.query.page < this.pageCount) {
        this.query.page++
        await this.getOrders()
      }
    },
    async goPrev() {
      if (this.query.page > 1) {
        this.query.page--
        await this.getOrders()
      }
    },
    fomateDateRow(date) {
      return formatDate(date)
    },
    formatWarehouse(warehouse) {
      return formatWarehouse(warehouse)
    },
    async submitSearch() {
      this.isSearch = !!this.searchItem
      this.isShow = false
      this.query.search = this.searchItem
      this.query.page = 1
      await this.getOrders()
    },
    clearSearch() {
      this.isShow = false
      this.isSearch = false
      this.searchItem = ''
      this.query.search = ''
      this.query.page = 1
    },
    async getSearchList() {
      const query = {
        limitSearch: this.limitSearch,
        search: this.searchItem,
      }

      const { value, error } =
        await delivery.AddwineCore.OrdersActions.getOffSellersSearchList(query)

      if (error) return

      this.searchProducts = value.data
    },
    async onSortChange(params) {
      this.query.orderBy = null
      this.query.orderDir = null
      this.query.page = 1
      if (params[0].type !== 'none') {
        this.query.orderBy = params[0].field
        this.query.orderDir = params[0].type
      }
      await this.getOrders()
    },
    getRoute({ route, params, query }) {
      return getRoute({ route, params, query })
    },
    getSale(order) {
      return Number(
        ((order?.productsPrice / 100) * order?.discountSize).toFixed(2) ?? 0,
      )
    },
    async handleClearSearch() {
      this.clearSearch()
      await this.getOrders()
    },
  },
}
</script>

<style lang="scss" module>
.container {
  padding: 1rem;
  h2 {
    padding: 1rem 0;
  }
  .status {
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 50%;
    background-color: $error;
  }
  .successStatus {
    background-color: $complite;
  }

  .actions {
    display: flex;
    .actionsBtn {
      display: flex;
      width: 100%;
      justify-content: flex-end;
    }
  }

  .search {
    margin-bottom: 5rem;
    max-width: 55.5rem;
  }
  .item {
    display: flex;
    align-items: center;

    border-bottom: 0.063rem solid $extra-light-gray;
    &:last-child {
      border-bottom: none;
    }

    .link {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      text-decoration: none;
      width: 100%;
      padding: 1rem 0;
      color: $black-gray;
    }
  }
}
</style>
